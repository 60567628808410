


















































































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DataFormat from '@/shared/utils/dataFormat';
import {placeModule, reservationModule, ticketModule} from '@/store';
import moment from 'moment';
import { EventBus } from '@/config/config';
import { Notify } from 'quasar';
import * as firebase from 'firebase/app';

@Component({
  components: {
    'reserve-modal': () => import('@/pages/modal/reservation/ReserveModal.vue'),
    'reserve-cancel': () => import('@/components/popup/ReserveCancel.vue'),
    'alert-modal': () => import('@/components/popup/AlertModal.vue')
  }
})
export default class PaymentResult extends mixins(DataFormat) {
  @Prop()
  public type!: string;
  @Prop()
  public selectLang!: any;

  public reserveParams: any = {
    date: moment().add(1, 'days').format('YYYY-MM-DD'),
    peopleCount: 2,
    peopleDetailCount: {},
    time: null,
    tableType: '',
    requestText: '',
    listBuyOption: [],
    calendarTableType: 'all',
    listTime: null,
    name: '',
    phone: '',
    email: '',
    countryCode: '',
    totalPrice: 0
  };
  public errorMessage: string = '';
  public cancelMessage: string = '';
  public isCancel: boolean = true;
  public loading: boolean = false;

  get reservation(): any {
    return reservationModule.getDetailReservation;
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      const id = encodeURIComponent(window.atob(to.query.reservation_id));
      localStorage.removeItem('cartData');
      reservationModule.fetchDetailReservation(to.query.reservation_id).then((res: any) => {
        vm.eventGoal(res.data);
      });
    });
  }

  public refresh() {
    reservationModule.fetchDetailReservation(this.$route.query.reservation_id);
  }

  public eventGoal(data) {
    if (this.reservation.type === 'ticket') {
      const eventName = 'goal_product';
      const eventParams: any = {
        id: data.product.id.toString(),
        name: data.product.title,
        order_id: data.order.id.toString(),
        pay_type: data.order.payment_info.pay_type,
        total_price: data.order.payment_info.total_price,
        cash_price: data.order.payment_info.cash_price,
        option_id: null,
        option_name: null,
        option_price: null,
        option_count: null,
        reservation_id: data.id.toString()
      }
      for (const option of data.buy_option) {
        eventParams.option_id = option.product_option_id.toString()
        eventParams.option_name = option.title
        eventParams.option_price = option.discount_price
        eventParams.option_count = option.count
      }
      firebase.analytics().logEvent(eventName, eventParams);
    } else {
      const eventName = 'goal_place';
      const eventParams: any = {
        id: this.reservation.place_id.toString(),
        name: this.place.name,
        type: this.reservation.status,
        reserve_date: this.reservation.reservation_date,
        party_size: this.reservation.party_size,
        reservation_id: this.reservation.id.toString()
      }
      firebase.analytics().logEvent(eventName, eventParams);
    }
  }

  public copyNoti() {
    this.$q.notify({
      message: '예약 정보가 복사 되었습니다. <br />지금 바로 공유 해보세요.',
      html: true
    });
  }
  public addInfoText(item: any) {
    if (
      item.key === 'customer_allergy_detail' ||
      item.title === '알러지 세부사항'
    ) {
      return item.value;
    }
    if (typeof(item.value) === 'object') {
      if (item.value[0] && item.value[0].title_detail === '') {
        const text: any = [];
        Object.values(item.value).map((row: any) => {
          text.push(row.title);
        });
        return text.join(', ');
      } else {
        const text: any = [];
        Object.values(item.value).filter((row: any) => {
          text.push(
            `${row.title} ${row.title_detail ? `(${row.title_detail})` : ''}`
          )
        });
        return text.join(` / `);
      }
    } else {
      return item.value;
    }
  }
  public reservCancelModal() {
    if (this.reservation.type === 'ticket') {
      document.querySelector('html' as any).classList.add('open-modal');
      this.$modal.show('reserveCancel');
    } else {
      const now: any = moment().add(30, 'm').toDate();
      const reservationDate = moment(this.reservation.reservation_date).toDate();

      if (reservationDate > now) {
        this.cancelMessage = '예약을 취소하시겠습니까?';
        this.isCancel = true;
        this.$modal.show('cancel');
      } else {
        this.cancelMessage = '예약을 취소할 수 있는 \n시간이 지났습니다.';
        this.isCancel = false;
        this.$modal.show('cancel');
      }
    }
  }

  public reserveModal() {
    placeModule.fetchPlaceInfoData(this.reservation.place_id);
    const reservationDate = moment(this.reservation.reservation_date);
    this.reserveParams.date = reservationDate.format('YYYY-MM-DD');
    this.reserveParams.time = reservationDate.format('HH:mm');
    this.reserveParams.peopleCount = this.reservation.party_size;
    this.reserveParams.requestText = this.reservation.message;
    // placeModule.fetchPersonDetail(this.reservation.place_id);
    this.$modal.show('reserve');
  }

  public reservationChange() {
    let now: any = moment().toDate();
    if (this.reservation.type === 'ticket') {
      if (this.reservation.product.reservation_policy_data.minute) {
        const m = this.reservation.product.reservation_policy_data.minute.value;
        now = moment().add(m, 'm').toDate();
      } else {
        if (this.reservation.product.reservation_policy_data.hour) {
          const h = this.reservation.product.reservation_policy_data.hour.value;
          now = moment().add(h, 'h').toDate();
        }
      }
    }
    const reservationDate = moment(this.reservation.reservation_date).toDate();
    if (reservationDate > now) {
      this.reserveModal();
    } else {
      this.errorMessage = '예약을 변경할 수 있는 \n시간이 지났습니다.';
      EventBus.$emit('errorMessage', this.errorMessage);
      this.$modal.show('alert');
    }
  }
  public reservationCancel(id) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.$modal.hide('cancel');
    reservationModule.fetchReservationDelete(id).then((res: any) => {
      if (res.status) {
        Notify.create({
          message: '예약이 취소되었습니다.'
        });
        location.reload();
      } else {
        Notify.create({
          message: res.error.message
        });
      }
    })
    .finally(() => {
      this.loading = false;
    });
  }

  // 인원 성인만 있는지 체크
  get onlyAdultCheck() {
    const re = this.reservation.party_size_detail.filter((row: any) => {
      return row.title !== '성인' && Number(row.party_size) !== 0;
    });
    if (re.length) {
      return true;
    }
    return false;
  }
  // 마지막 인원 타입
  get lastPersonKindIndex() {
    let type = '';
    this.reservation.party_size_detail.filter((row: any) => {
      if (row.party_size !== 0) {
        type = row.title;
      }
    });
    if (type !== '') {
      return this.reservation.party_size_detail.findIndex((row: any) => {
        return row.title === type;
      })
    }
    return 0;
  }
  get shareText() {
    if (this.reservation.share_info) {
      const target = this.reservation.share_info.body.indexOf('http');
      if (target !== -1) {
        return this.reservation.share_info.body.substring(0, target);
      }
    }
    return this.reservation.share_info && this.reservation.share_info.body;
  }
  get statusMainText() {
    if (this.reservation.status === 'change_confirmed') {
      return '변경이 완료되었습니다.';
    } else if (this.reservation.status === 'cancelled') {
      return '예약이 취소되었습니다.';
    } else if (this.reservation.status === 'appeared') {
      return '방문 처리되었습니다.';
    } else if (this.reservation.status === 'noshow') {
      return '노쇼 처리되었습니다.';
    } else if (this.reservation.status === 'confirmed' && this.reservation.type === 'ticket') {
      return '티켓 예약이 확정되었습니다.';
    } else if (this.reservation.status === 'confirmed' && this.reservation.type !== 'ticket') {
      return '예약이 확정되었습니다.';
    } else if (this.reservation.status === 'wait_confirm') {
      return `예약이 접수되어 <br />매장에서 확인 중이에요.`;
    } else if (this.reservation.status === 'change_wait_confirm') {
      return `변경 요청 내용을<br />매장에서 확인 중이에요.`;
    }
    // 예약접수 : wait_confirm, 변경접수 : change_wait_confirm
  }
  get place(): any {
    return placeModule.placeInfoData;
  }
}
